import { miraieFields } from '@terass/common/src'
import { Suspense } from 'react'

import { SearchableSelect } from '../../controls'

import { ErrorMessage } from './ErrorMessage'
import { LoadingSelect } from './controls'
import { useAddressFormContext, usePrefectureList } from './hooks'

export const PrefectureSelect = () => (
  <Suspense fallback={<LoadingSelect />}>
    <PrefectureSelect_ />
  </Suspense>
)

const PrefectureSelect_ = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useAddressFormContext()
  const { onChange, ...other } = register('prefectureCode', {
    required: '住所の入力は必須です',
  })
  const prefectureOptions = usePrefectureList()

  return (
    <>
      <SearchableSelect
        isError={!!errors.prefectureCode}
        onChange={(e) => {
          // 下位の住所を未選択にしてから、react hook formのonChangeを呼ぶ
          setValue(
            'shikuchosonCode',
            miraieFields.gyosei_cd.shikuchoson.Code.未選択,
          )
          setValue('ooazaCode', miraieFields.aza_cd.ooaza.Code.未選択)
          setValue('azaCode', miraieFields.aza_cd.aza.Code.未選択)
          onChange(e)
        }}
        {...other}
      >
        {prefectureOptions.map((e) => (
          <option key={e.id} value={e.id}>
            {e.data.name}
          </option>
        ))}
      </SearchableSelect>
      {errors.prefectureCode && (
        <ErrorMessage>{errors.prefectureCode.message}</ErrorMessage>
      )}
    </>
  )
}
