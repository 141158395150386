import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { setOtherErrors } from '@terass/common/src/miraie/PostMiraieV1ImportResponse'
import { portals } from '@terass/common/src/portal'
import { MdError } from 'react-icons/md'

import { Contents } from '@/components/Contents'
import { PortalIcon, PortalIconContainer } from '@/components/icons'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

export const FieldErrorsMenu = () => {
  const errors = setOtherErrors(
    useV1ImportDocument(({ __response }) => __response),
  )

  if (!Object.keys(errors).length) return null

  const errorEntries = Object.entries(errors)
  const onClickMenuItem = (fieldName: string) => () => {
    const target = document.querySelector(`*[name="${fieldName}"]`)
    if (target instanceof HTMLElement) target.focus()
  }

  return (
    <Menu matchWidth>
      <MenuButton
        as={Button}
        colorScheme="red"
        position="sticky"
        top={16}
        right={4}
        zIndex="sticky"
        leftIcon={<MdError />}
        rightIcon={<ChevronDownIcon />}
      >
        {errorEntries.length} 件のエラーがあります
      </MenuButton>
      <MenuList overflowY="auto" maxH="80vh">
        {errorEntries.map(([fieldName, errors]) => (
          <MenuItem
            key={fieldName}
            onClick={onClickMenuItem(fieldName)}
            flexDir="column"
            alignItems="start"
          >
            <Box fontSize="xs">{fieldName}</Box>
            <Grid templateColumns="auto 1fr" alignItems="center">
              {(['公取', 'logParam', 'validate', ...portals] as const).map(
                (portal) =>
                  errors?.[portal] && (
                    <Contents key={portal}>
                      <PortalIconContainer width={6} height={6}>
                        <PortalIcon portal={portal} />
                      </PortalIconContainer>
                      <Box fontSize="sm">{errors[portal]}</Box>
                    </Contents>
                  ),
              )}
            </Grid>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
