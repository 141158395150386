import {
  Button,
  ButtonProps,
  Checkbox,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'
import { arrayUtil } from '@terass/common/src/utils'
import { useForm } from 'react-hook-form'

import { Contents } from '@/components/Contents'
import { ConfirmAlertDialog } from '@/components/form/ConfirmAlertDialog'
import { useIsEdit } from '@/hooks/useIsEdit'
import {
  useMiraieFormContext,
  useMiraieFormController,
} from '@/hooks/useMiraieForm'

export const HoreiKubunEditButton = (props: ButtonProps) => {
  const {
    field: { ref, value },
  } = useMiraieFormController({ name: 'horei_kbn_renketsu' })
  const { setValue } = useMiraieFormContext()
  const isEdit = useIsEdit()
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure()
  const {
    handleSubmit,
    reset,
    register,
    formState: { isDirty },
  } = useForm<Pick<PostMiraieV1ImportForm, 'horei_kbn_renketsu'>>({
    values: {
      horei_kbn_renketsu: value,
    },
  })
  const onClose = () => {
    isDirty ? onAlertOpen() : onModalClose()
  }
  const onAbort = () => {
    onAlertClose()
    onModalClose()
    reset()
  }
  const onSubmit = handleSubmit(({ horei_kbn_renketsu }) => {
    setValue('horei_kbn_renketsu', horei_kbn_renketsu || [])
    onModalClose()
  })

  return (
    <>
      <Button ref={ref} disabled={!isEdit} onClick={onModalOpen} {...props} />
      <ConfirmAlertDialog
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        onAbort={onAbort}
      />
      <Modal
        size="6xl"
        isOpen={isModalOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <Contents as="form" onSubmit={onSubmit}>
          <ModalContent>
            <ModalHeader>法令制限区分連結</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Grid templateColumns="repeat(auto-fit, minmax(350px, 1fr))">
                {arrayUtil
                  .chunk(miraieFields.horei_kbn_renketsu.values, 10)
                  .map((values, i) => (
                    <Flex
                      key={values.join(',')}
                      flexDirection="column"
                      width="fit-content"
                      mb={4}
                      align="stretch"
                      gap={1}
                    >
                      <Text fontWeight="bold">{`(${i + 1})`}</Text>
                      {values.map((value) => (
                        <Checkbox
                          {...register('horei_kbn_renketsu')}
                          id={`horei-kbn-${value}`}
                          key={value}
                          value={value}
                        >
                          {miraieFields.horei_kbn_renketsu.getLabel(value)}
                        </Checkbox>
                      ))}
                    </Flex>
                  ))}
              </Grid>
            </ModalBody>
            <ModalFooter gap={4}>
              <Button colorScheme="gray" onClick={onClose}>
                キャンセル
              </Button>
              <Button type="submit">完了</Button>
            </ModalFooter>
          </ModalContent>
        </Contents>
      </Modal>
    </>
  )
}
