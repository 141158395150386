/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LayoutImport } from './routes/_layout'
import { Route as FuturesIndexImport } from './routes/futures/index'
import { Route as ContractsIndexImport } from './routes/contracts/index'
import { Route as LayoutIndexImport } from './routes/_layout/index'
import { Route as BukkensImportIdIndexImport } from './routes/bukkens/$importId/index'
import { Route as BukkensImportIdEditIndexImport } from './routes/bukkens/$importId/edit/index'

// Create/Update Routes

const LayoutRoute = LayoutImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any)

const FuturesIndexRoute = FuturesIndexImport.update({
  id: '/futures/',
  path: '/futures/',
  getParentRoute: () => rootRoute,
} as any)

const ContractsIndexRoute = ContractsIndexImport.update({
  id: '/contracts/',
  path: '/contracts/',
  getParentRoute: () => rootRoute,
} as any)

const LayoutIndexRoute = LayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LayoutRoute,
} as any)

const BukkensImportIdIndexRoute = BukkensImportIdIndexImport.update({
  id: '/bukkens/$importId/',
  path: '/bukkens/$importId/',
  getParentRoute: () => rootRoute,
} as any)

const BukkensImportIdEditIndexRoute = BukkensImportIdEditIndexImport.update({
  id: '/bukkens/$importId/edit/',
  path: '/bukkens/$importId/edit/',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutImport
      parentRoute: typeof rootRoute
    }
    '/_layout/': {
      id: '/_layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof LayoutIndexImport
      parentRoute: typeof LayoutImport
    }
    '/contracts/': {
      id: '/contracts/'
      path: '/contracts'
      fullPath: '/contracts'
      preLoaderRoute: typeof ContractsIndexImport
      parentRoute: typeof rootRoute
    }
    '/futures/': {
      id: '/futures/'
      path: '/futures'
      fullPath: '/futures'
      preLoaderRoute: typeof FuturesIndexImport
      parentRoute: typeof rootRoute
    }
    '/bukkens/$importId/': {
      id: '/bukkens/$importId/'
      path: '/bukkens/$importId'
      fullPath: '/bukkens/$importId'
      preLoaderRoute: typeof BukkensImportIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/bukkens/$importId/edit/': {
      id: '/bukkens/$importId/edit/'
      path: '/bukkens/$importId/edit'
      fullPath: '/bukkens/$importId/edit'
      preLoaderRoute: typeof BukkensImportIdEditIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

interface LayoutRouteChildren {
  LayoutIndexRoute: typeof LayoutIndexRoute
}

const LayoutRouteChildren: LayoutRouteChildren = {
  LayoutIndexRoute: LayoutIndexRoute,
}

const LayoutRouteWithChildren =
  LayoutRoute._addFileChildren(LayoutRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof LayoutRouteWithChildren
  '/': typeof LayoutIndexRoute
  '/contracts': typeof ContractsIndexRoute
  '/futures': typeof FuturesIndexRoute
  '/bukkens/$importId': typeof BukkensImportIdIndexRoute
  '/bukkens/$importId/edit': typeof BukkensImportIdEditIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof LayoutIndexRoute
  '/contracts': typeof ContractsIndexRoute
  '/futures': typeof FuturesIndexRoute
  '/bukkens/$importId': typeof BukkensImportIdIndexRoute
  '/bukkens/$importId/edit': typeof BukkensImportIdEditIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_layout': typeof LayoutRouteWithChildren
  '/_layout/': typeof LayoutIndexRoute
  '/contracts/': typeof ContractsIndexRoute
  '/futures/': typeof FuturesIndexRoute
  '/bukkens/$importId/': typeof BukkensImportIdIndexRoute
  '/bukkens/$importId/edit/': typeof BukkensImportIdEditIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/'
    | '/contracts'
    | '/futures'
    | '/bukkens/$importId'
    | '/bukkens/$importId/edit'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/contracts'
    | '/futures'
    | '/bukkens/$importId'
    | '/bukkens/$importId/edit'
  id:
    | '__root__'
    | '/_layout'
    | '/_layout/'
    | '/contracts/'
    | '/futures/'
    | '/bukkens/$importId/'
    | '/bukkens/$importId/edit/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  LayoutRoute: typeof LayoutRouteWithChildren
  ContractsIndexRoute: typeof ContractsIndexRoute
  FuturesIndexRoute: typeof FuturesIndexRoute
  BukkensImportIdIndexRoute: typeof BukkensImportIdIndexRoute
  BukkensImportIdEditIndexRoute: typeof BukkensImportIdEditIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  LayoutRoute: LayoutRouteWithChildren,
  ContractsIndexRoute: ContractsIndexRoute,
  FuturesIndexRoute: FuturesIndexRoute,
  BukkensImportIdIndexRoute: BukkensImportIdIndexRoute,
  BukkensImportIdEditIndexRoute: BukkensImportIdEditIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_layout",
        "/contracts/",
        "/futures/",
        "/bukkens/$importId/",
        "/bukkens/$importId/edit/"
      ]
    },
    "/_layout": {
      "filePath": "_layout.tsx",
      "children": [
        "/_layout/"
      ]
    },
    "/_layout/": {
      "filePath": "_layout/index.tsx",
      "parent": "/_layout"
    },
    "/contracts/": {
      "filePath": "contracts/index.tsx"
    },
    "/futures/": {
      "filePath": "futures/index.tsx"
    },
    "/bukkens/$importId/": {
      "filePath": "bukkens/$importId/index.tsx"
    },
    "/bukkens/$importId/edit/": {
      "filePath": "bukkens/$importId/edit/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
