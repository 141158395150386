import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { createFileRoute } from '@tanstack/react-router'
import { V1Import } from '@terass/common/src/firestore/V1Import'
import { RefObject, Suspense, useRef } from 'react'
import useSessionStorageState from 'use-session-storage-state'

import { BukkenRow } from '@/components/BukkenRow'
import { TbodySkeleton } from '@/components/TbodySkeleton'
import { useOperator } from '@/hooks/useOperator'
import {
  FilterConditions,
  statuses,
  statusLabel,
  Type,
  typeLabel,
  types,
  useV1ImportCollection,
} from '@/hooks/useV1ImportCollection'
import { NavigationButtonGroup } from '@/routes/_layout/-components'
import { cursorParamsSchema } from '@/routes/_layout/-cursorParamsSchema'

export const Route = createFileRoute('/_layout/')({
  validateSearch: cursorParamsSchema,
  component: Top,
})

function Top() {
  const { id } = useOperator()
  const [filterConditions, setFilterConditions] =
    useSessionStorageState<FilterConditions>(`filterConditions:${id}`, {
      defaultValue: {
        status: ['draft', 'published'],
        type: ['contracts', 'futures'],
      },
    })
  const navButtonContainerRef = useRef<HTMLDivElement>(null)
  const textContainerRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <Flex gap={2} mt={4}>
        <Menu closeOnSelect={false}>
          <FilterMenuButton isFiltered={filterConditions.status.length !== 0}>
            ステータス:{' '}
            {filterConditions.status
              .map((status) => statusLabel[status])
              .join(', ')}
          </FilterMenuButton>
          <Portal>
            <MenuList>
              <MenuOptionGroup
                type="checkbox"
                title="ステータス"
                defaultValue={filterConditions.status}
                onChange={(v) =>
                  setFilterConditions((prev) => ({
                    ...prev,
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    status: v as V1Import['status'][],
                  }))
                }
              >
                {statuses.map((status) => (
                  <MenuItemOption key={status} value={status}>
                    {statusLabel[status]}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
        <Menu closeOnSelect={false}>
          <FilterMenuButton isFiltered={filterConditions.type.length !== 0}>
            契約種別:{' '}
            {filterConditions.type.map((t) => typeLabel[t]).join(', ')}
          </FilterMenuButton>
          <Portal>
            <MenuList>
              <MenuOptionGroup
                type="checkbox"
                title="契約種別"
                defaultValue={filterConditions.type}
                onChange={(v) =>
                  setFilterConditions((prev) => ({
                    ...prev,
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    type: v as Type[],
                  }))
                }
              >
                {types.map((type) => (
                  <MenuItemOption key={type} value={type}>
                    {typeLabel[type]}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </Flex>
      <Box overflowX="scroll">
        <Table fontSize="sm" layout="fixed" mt={4}>
          <Thead bgColor="teraSeven.50">
            <Tr>
              {/* ハンバーガーメニュー */}
              <Th width={12} />
              <Th width={140}>ステータス</Th>
              <Th width={150}>物件識別番号</Th>
              <Th width={350}>物件名・住所・価格</Th>
              <Th width={160}>掲載開始日</Th>
              <Th width={160}>掲載終了日</Th>
              <Th width={140}>契約種別</Th>
              <Th width={160}>媒介契約期間</Th>
              <Th width={150}>売主名</Th>
              <Th width={140}>更新日</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Suspense fallback={<TbodySkeleton rows={3} cols={10} />}>
              <V1ImportItems
                filterConditions={filterConditions}
                navButtonContainerRef={navButtonContainerRef}
                textContainerRef={textContainerRef}
              />
            </Suspense>
          </Tbody>
        </Table>
      </Box>
      <Flex justifyContent="space-between">
        <Box ref={textContainerRef} />
        <Box ref={navButtonContainerRef} />
      </Flex>
    </>
  )
}

const V1ImportItems = ({
  filterConditions,
  navButtonContainerRef,
  textContainerRef,
}: {
  filterConditions: FilterConditions
  navButtonContainerRef: RefObject<HTMLDivElement>
  textContainerRef: RefObject<HTMLDivElement>
}) => {
  const navigate = Route.useNavigate()
  const { direction = 'previous', cursor } = Route.useSearch()
  const { email } = useOperator()
  const { items, ...pageResult } = useV1ImportCollection({
    direction,
    cursor,
    where: {
      status: filterConditions.status,
      readerEmail: email,
      type: filterConditions.type,
    },
    orderBy: {
      field: 'updatedAt',
      direction: 'desc',
    },
    onNext: (snapshot) => {
      navigate({
        to: Route.to,
        search: { direction: 'next', cursor: snapshot.id },
      })
    },
    onPrevious: (snapshot) => {
      navigate({
        to: Route.to,
        search: { direction: 'previous', cursor: snapshot.id },
      })
    },
  })
  return items.length ? (
    <>
      {items.map(({ id, data }) => (
        <BukkenRow key={id} id={id} v1import={data} />
      ))}
      <Portal containerRef={navButtonContainerRef}>
        <NavigationButtonGroup
          {...pageResult}
          firstPage={() => navigate({ to: Route.to })}
          lastPage={() =>
            navigate({ to: Route.to, search: { direction: 'next' } })
          }
        />
      </Portal>
    </>
  ) : (
    <Portal containerRef={textContainerRef}>
      <Text>条件に一致する物件がありません。</Text>
    </Portal>
  )
}

const FilterMenuButton = ({
  isFiltered,
  ...props
}: { isFiltered: boolean } & MenuButtonProps) => {
  return (
    <MenuButton
      as={Button}
      rightIcon={<ChevronDownIcon />}
      colorScheme="ghost"
      bgColor={isFiltered ? 'blue.50' : 'gray.50'}
      color={isFiltered ? 'blue.500' : 'gray.500'}
      _hover={{ bgColor: isFiltered ? 'blue.100' : 'gray.100' }}
      size="xs"
      borderRadius={10}
      borderWidth={1}
      borderColor={isFiltered ? 'blue.300' : 'gray.300'}
      {...props}
    />
  )
}
