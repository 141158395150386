import { ExternalLinkIcon, QuestionIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Link, Progress } from '@chakra-ui/react'
import { createRootRoute } from '@tanstack/react-router'

import { ErrorFallback } from '@/components/ErrorFallback'
import { Header } from '@/components/Header'
import { LoginPage } from '@/components/LoginPage'
import { Main } from '@/components/Main'
import { PageView } from '@/components/PageView'
import { LogoutButton } from '@/components/auth/LogoutButton'
import { MasqueradeAlert } from '@/components/auth/MasqueradeAlert'
import { HelpButton } from '@/components/help/HelpModal'
import { CONTACT_LINK } from '@/constants'
import { useLoginState } from '@/hooks/useUser'
import { AuthenticatedLayout, authLoader } from '@/routes/-authenticatedLayout'

export const Route = createRootRoute({
  component: Root,
  loader: authLoader,
  errorComponent: ErrorFallback,
  pendingComponent: () => <Progress isIndeterminate size="sm" />,
})

export function Root() {
  const authState = useLoginState()

  return (
    <>
      <PageView />
      <Header>
        <Flex gap={4}>
          {authState && (
            <>
              <HelpButton leftIcon={<QuestionIcon />}>ヘルプ</HelpButton>
              <Button
                as={Link}
                href={CONTACT_LINK}
                isExternal
                leftIcon={<ExternalLinkIcon />}
              >
                お問い合わせ
              </Button>
              <LogoutButton />
            </>
          )}
        </Flex>
      </Header>

      {authState && (
        <Box position="sticky" top="0" zIndex="sticky">
          <MasqueradeAlert />
        </Box>
      )}

      <Main>{authState ? <AuthenticatedLayout /> : <LoginPage />}</Main>
    </>
  )
}
