import { WarningIcon } from '@chakra-ui/icons'
import {
  SimpleGrid,
  Box,
  SimpleGridProps,
  Text,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Icon,
} from '@chakra-ui/react'
import { ngWordsTargetFields } from '@terass/common/src/constants/ngWords'
import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'
import {
  createErrorMessage,
  validateNgWords,
} from '@terass/common/src/tera-seven/ngWords'
import { PropsWithChildren, useCallback, useState } from 'react'

import { useFormControlContext } from '@/components/form/controls/FormControl'
import { createContext } from '@/hooks/useContext'
import { MiraieFieldPath } from '@/hooks/useMiraieForm'

type Errors = { key: string; messages: string[] }[]

type FrontendValidationContext = {
  errors: Errors
  validate: (formValues: PostMiraieV1ImportForm) => boolean
}

const [Provider, useFrontendValidation] =
  createContext<FrontendValidationContext>()

export function FrontendValidationProvider(props: PropsWithChildren) {
  const [errors, setErrors] = useState<FrontendValidationContext['errors']>([])

  const validate = useCallback((formValues: PostMiraieV1ImportForm) => {
    const errs: Errors = []
    ngWordsTargetFields.forEach((fieldName) => {
      if (
        fieldName in formValues &&
        typeof formValues[fieldName] === 'string'
      ) {
        const { error, ngWords } = validateNgWords(formValues[fieldName])
        if (error) {
          const message = createErrorMessage(ngWords)
          errs.push({ key: fieldName, messages: [message] })
        }
      }
    })
    if (errs.length) {
      setErrors(errs)
      return true
    }
    setErrors([])
    return false
  }, [])

  return <Provider value={{ errors, validate }} {...props} />
}

export function FrontendValidationErrorMessages({
  fieldName: fieldNameProp,
  ...gridProps
}: {
  fieldName?: MiraieFieldPath
} & SimpleGridProps) {
  const { fieldName: _fieldName } = useFormControlContext()
  const fieldName = fieldNameProp ?? _fieldName
  if (!fieldName) {
    throw new Error()
  }
  const { errors } = useFrontendValidation()
  const error = errors.find(({ key }) => key === fieldName)
  if (!error) {
    return null
  }
  return (
    <SimpleGrid columns={1} gap={1} mt={2} {...gridProps}>
      {error.messages.map((message, index) => (
        <Box key={index}>
          <Text size="sm" color="red">
            {message}
          </Text>
        </Box>
      ))}
    </SimpleGrid>
  )
}

export function FrontendValidationContainer({ children }: PropsWithChildren) {
  return (
    <Flex>
      <Box flex={1}>{children}</Box>
      <FrontendValidationErrorMessagesCard />
    </Flex>
  )
}

export function FrontendValidationErrorMessagesCard() {
  const { errors } = useFrontendValidation()
  if (!errors.length) {
    return null
  }
  const onClickMenuItem = (fieldName: string) => () => {
    const target = document.querySelector(`*[name="${fieldName}"]`)
    if (target instanceof HTMLElement) target.focus()
  }
  return (
    <Box flex={2} ml={4} minW={380}>
      <Card position="sticky" top={20} right={10} overflowY="auto" maxH="80vh">
        <CardHeader paddingBottom="10px">
          <Heading size="md" color="red.500">
            <Icon as={WarningIcon} marginRight="6px" marginBottom="2px" />
            不正な値の項目があります
          </Heading>
        </CardHeader>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            {errors.map(({ key, messages }) => (
              <Box
                key={key}
                _hover={{ bg: 'gray.50' }}
                cursor="pointer"
                onClick={onClickMenuItem(key)}
              >
                <Heading size="xs" color="red.500" marginBottom="4px">
                  ・{key}
                </Heading>
                <Text color="red.500">{`${messages[0]}`}</Text>
              </Box>
            ))}
          </Stack>
        </CardBody>
      </Card>
    </Box>
  )
}

export { useFrontendValidation }
